import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";

const AddLeadstoAgent = () => {
  const [user, setUser] = useState();
  const [toggleSelect, setToggleSelect] = useState(true);
  const { activeToken } = Token();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(100); // Set your page size

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { id } = useParams ();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    password: '',
    status: 'active'
  });

  const handleSubmit = async () => {
    setDisabledBtn(true);
    Axios.put(`${axiosLink}leads/assign-agent`, { id: id, leads: selectedUserIds}, {
      headers: {
        token: activeToken,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response?.data?.msg) {
        setSuccessMsg(response?.data?.msg);
        setTimeout(() => {
          navigate("/admin/agents");
          setDisabledBtn(false);
        }, 5000);
      } else {
        setDisabledBtn(false);
        setErrorResponse(response.data?.errMsg);
        setTimeout(() => {
          setErrorResponse();
        }, 8000);
      }
    }).catch((err) => console.log(err));
  };

  const setAssignAll = () => {
    if (toggleSelect) {
      const allIds = user?.map(element => element.id);
      setSelectedUserIds(allIds);
    } else {
      setSelectedUserIds([]);
    }
    setToggleSelect(!toggleSelect);
  };

  const setAssign = (userId) => {
    setSelectedUserIds(prevIds => {
      if (prevIds.includes(userId)) {
        return prevIds.filter(id => id !== userId); // Deselect if already selected
      } else {
        return [...prevIds, userId]; // Select if not already selected
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData(page);
    setSelectedUserIds([]);
  };
  const getPaginationRange = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    
    return pages;
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
    getData(1, Number(event.target.value));
    setSelectedUserIds([]);
  };

  const getData = (page, nrRows) => {
    const nrPageSize = nrRows || pageSize;
    Axios.get(`${axiosLink}leads/free?offset=${(page - 1) * nrPageSize}&limit=${nrPageSize}`,
      {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
      .then((response) => {
        setUser(response?.data?.data);
        setTotalPages(Math.ceil(response?.data?.totalCount / nrPageSize)); // Assuming your API returns total count
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (id) {
      Axios.get(`${axiosLink}users/one-client-full?id=${id}`,{
        headers: {
          token: activeToken,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if(response.data){
          setFormData(response.data);
        } else {
          navigate("/");
        }
      })
      .catch((err) => console.log(err))
    }
    getData(1);
  }, []);
  return (
    <div className='container-fluid'>
      <div className="row my-4">
          <div className="col-9">
            <h2>Agent: {formData?.name} {formData?.last_name} - {formData?.email}</h2>
          </div>
          <div className="col-3 justify-content-end d-flex my-3">
            <button type="button" onClick={() => handleSubmit()} className="btn btn-primary mx-3" disabled={disabledBtn || user?.length === 0 || selectedUserIds.length === 0}>Assign</button>
            <button type="button" onClick={() => setAssignAll()} className={`${!toggleSelect ? "btn-danger" : "btn-primary"} btn  mx-1`} disabled={disabledBtn || user?.length === 0}>{toggleSelect ? "Select all": "Deselect all"}</button>
          </div>
      </div>
      <div className="row my-2">
        <div className="col-12">
          <div className="container-fluid table-responsive">
            {errorResponse && <p className="pt-4 he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorResponse}</p>}
            {successMsg && <p className="pt-4 color-green">{successMsg}</p>}
            {user ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Affiliate Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Country</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Created AT</th>
                    <th scope="col">Source Type</th>
                    <th scope="col">Source Comment</th>
                    <th scope="col">Agent comment</th>
                    <th scope="col">Try to call</th>
                    <th scope="col">Repeated leads</th>
                    <th scope="col">Assign to agent</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.length === 0 && <tr><td colSpan={12}>No data!</td></tr>}
                  {user?.map((el, index) => (
                    <tr key={el.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.first_name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.affiliate_name}</td>
                      <td>{el.email}</td>
                      <td>{el.phone}</td>
                      <td>{el.country}</td>
                      <td>{el.currency}</td>
                      <td>{el.created}</td>
                      <td>{el.source_type}</td>
                      <td>{el.source_comment}</td>
                      <td>{el.agent_comment}</td>
                      <td>{el.try_call}</td>
                      <td>{el.repeated_leads}</td>
                      <td><button type='button' onClick={() => setAssign(el.id)} className={`${selectedUserIds.includes(el.id) ? "btn-danger" : "btn-primary"} btn  my-1`}>{selectedUserIds.includes(el.id) ? "Remove" : "Add"}</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
              <div className="col-12">
                <div className="pagination">
                  <button
                    className='page-link'
                    disabled={currentPage === 1} 
                    onClick={() => handlePageChange(currentPage - 1)}>
                    Previous
                  </button>
                  {getPaginationRange().map((page) => (
                    <button 
                      key={page} 
                      onClick={() => handlePageChange(page)} 
                      className={currentPage === page ? 'mx-2 page-link active' : 'page-link page-item mx-2'}>
                      {page}
                    </button>
                  ))}
                  <button 
                    className='page-link'
                    disabled={currentPage === totalPages} 
                    onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                  </button>
                  <div>
                    <select id="pageSize" value={pageSize} onChange={handlePageSizeChange} className="form-select w-auto mx-2">
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddLeadstoAgent;
