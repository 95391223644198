import React from 'react';
import { Navigate } from 'react-router-dom';
import Token from './Token';

const PrivateRoute = ({ children, roleUser }) => {
  const token = Token();
  if (roleUser && token.role !== roleUser) {
    localStorage.removeItem("token");
    return <Navigate to="/" replace />;
  }

  // Check if token is invalid or not present
  if (!token || !token.activeToken) {
    // console.log("Token does not exist or is invalid:", token);
    localStorage.removeItem("token");
    return <Navigate to="/" replace />;
  }

  // Token is valid, allow access to children components
  // console.log("Token exists and is valid:", token);
  return children;
}

export default PrivateRoute;
