import React from 'react';

const Modal = ({ showModal, handleClose, children, title, btn, triggerSave }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) { // Ensure click is on the backdrop, not children
      handleClose();
    }
  };

  return (
    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={triggerSave}>{btn}</button>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show" onClick={handleBackdropClick}></div>}
    </div>
  );
};

export default Modal;
