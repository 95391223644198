
import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Token from '../../PrivateRoutes/Token';

function Header({ menu }) {
    const token = Token();
    const navigate = useNavigate();
    const LogOut = () => {
      localStorage.removeItem('token');
      navigate("/");
    }
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Link className="px-4 nav-link" to="#"><b>ABC</b></Link>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0 nav-link"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        {menu?.map((el) => (
                            <Link className="nav-link" key={el.path} to={el.path}>{el.name}</Link>
                        ))}
                    </Nav>
                    <div>
                        <span className="px-4">{token?.user}</span>
                        <Button onClick={() => LogOut()} variant="outline-success">Log out</Button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
  }
  
  export default Header;