import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import Login from './components/Login';
import Maintainer from './components/Maintainer/Maintainer';
import PrivateRoute from './PrivateRoutes/PrivateRoute';
import Agent from './components/User/Agent';
import AgentLeads from './components/User/Leads';
import Admin from './components/Admin/Admin';
import Header from './components/Header';
import { menuItemAdmin, menuItemMaintainer, menuItemUser } from './assets/menu';
import Users from './components/Admin/Menager';
import Agents from './components/Admin/Agents';
import AddMenager from './components/Admin/AddMenager';
import AddAgent from './components/Admin/AddAgent';
import Leads from './components/Admin/Leads';
import AddLeads from './components/Admin/AddLeads';
import LeadsFree from './components/Admin/LeadsFree';
import LeadsAssign from './components/Admin/LeadsAssign';
import MaintainerAgents from './components/Maintainer/Agents';
import LeadsFreeMaintainer from './components/Maintainer/LeadsFree';
import LeadsAssignMaintainer from './components/Maintainer/LeadsAssign';
import LeadsMaintainer from './components/Maintainer/Leads';
import LeadaddMaintainer from './components/Maintainer/AddLeads';
import MaintainerAgent from './components/Maintainer/AddMenager';
import AddLeadstoAgent from './components/Maintainer/AddLeadstoAgent';
import AddLeadstoAgentAdmin from './components/Admin/AddLeadstoAgent';
import UserInfo from './components/User/UserInfo';
import PublishedFree from './components/Admin/PublishedFree';
import LeadsRepeatedFree from './components/Admin/LeadsRepeatedFree';
import LeadsRepeatedPublishedFree from './components/Admin/LeadsRepeatedPublishedFree';
import ResetPassword from './components/Admin/ResetPassword';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <Admin menu />
          </PrivateRoute>
        } />
        <Route path="/admin/menagers" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <Users menu />
          </PrivateRoute>
        } />
        <Route path="/admin/menagers/add" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddMenager />
          </PrivateRoute>
        } />
        <Route path="/admin/menagers/add/:id" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddMenager />
          </PrivateRoute>
        } />
        <Route path="/admin/agents" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <Agents />
          </PrivateRoute>
        } />
        <Route path="/leads" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <Leads />
          </PrivateRoute>
        } />
        <Route path="/leads/add" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddLeads />
          </PrivateRoute>
        } />
        <Route path="/leads/add/:id" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddLeads />
          </PrivateRoute>
        } />
        <Route path="/admin/agents/add" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddAgent />
          </PrivateRoute>
        } />
        <Route path="/admin/agents/update/:id" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddAgent />
          </PrivateRoute>
        } />
        <Route path="/leads/free" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <LeadsFree />
          </PrivateRoute>
        } />
        <Route path="/leads/free-old" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <LeadsRepeatedFree />
          </PrivateRoute>
        } />
        <Route path="/leads/assign" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <LeadsAssign />
          </PrivateRoute>
        } />
        <Route path="/leads/publish-leads" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <PublishedFree />
          </PrivateRoute>
        } />
        <Route path="/leads/publish-leads-old" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <LeadsRepeatedPublishedFree />
          </PrivateRoute>
        } />
        <Route path="/admin/agent/addleads/:id" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <AddLeadstoAgentAdmin />
          </PrivateRoute>
        } />
        <Route path="/admin/pass" element={
          <PrivateRoute roleUser='admin'>
            <Header menu={menuItemAdmin} />
            <ResetPassword />
          </PrivateRoute>
        } />

        <Route path="/maintainer" element={
          <PrivateRoute roleUser='maintainer'>
            <Header menu={menuItemMaintainer} />
            <Maintainer />
          </PrivateRoute>
        } />
        <Route path="/maintainer/agent/:id" element={
          <PrivateRoute roleUser='maintainer'>
            <Header menu={menuItemMaintainer} />
            <MaintainerAgent />
          </PrivateRoute>
        } />

        <Route path="/maintainer/agents" element={
          <PrivateRoute roleUser='maintainer'>
            <Header menu={menuItemMaintainer} />
            <MaintainerAgents />
          </PrivateRoute>
        } />
        <Route path="/maintainer/leads/free" element={
          <PrivateRoute roleUser='maintainer'>
            <Header menu={menuItemMaintainer} />
            <LeadsFreeMaintainer />
          </PrivateRoute>
        } />
        <Route path="/maintainer/agent/addleads/:id" element={
          <PrivateRoute roleUser='maintainer'>
            <Header menu={menuItemMaintainer} />
            <AddLeadstoAgent />
          </PrivateRoute>
        } />
        <Route path="/user" element={
          <PrivateRoute roleUser='user'>
            <Header menu={menuItemUser} />
            <Agent />
          </PrivateRoute>
        } />
        <Route path="/user/leads" element={
          <PrivateRoute roleUser='user'>
            <Header menu={menuItemUser} />
            <AgentLeads />
          </PrivateRoute>
        } />
        <Route path="/user/info" element={
          <PrivateRoute roleUser='user'>
            <Header menu={menuItemUser} />
            <UserInfo />
          </PrivateRoute>
        } />
 
        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
