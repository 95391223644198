import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Token from '../../PrivateRoutes/Token';
import { axiosLink } from '../../axiosLink';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusTermin } from '../../assets/status';

const AddLeads = () => {
  const { activeToken } = Token();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    affiliate_name: "",
    email: "",
    phone: "",
    country: "",
    currency: "",
    created: "",
    source_type: "",
    source_comment: "",
    responsablie: null,
    status: null
  });

  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
      if (id) {
        Axios.put(`${axiosLink}leads/update`, formData, {
          headers: {
            token: activeToken,
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response?.data?.msg) {
            setSuccessMsg(response?.data?.msg);
            setTimeout(() => {
              navigate("/leads");
            }, 5000);
          } else {
            setDisabledBtn(false);
            setErrorResponse(response?.data?.errMsg?.name);
            setTimeout(() => {
              setErrorResponse();
            }, 8000);
          }
        }).catch((err) => console.log(err));
      } else {
        Axios.post(`${axiosLink}leads/create`, formData, {
          headers: {
            token: activeToken,
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response?.data?.msg) {
            setSuccessMsg(response?.data?.msg);
            setTimeout(() => {
              navigate("/leads");
            }, 5000);
          } else {
            setDisabledBtn(false);
            setErrorResponse(response?.data?.errMsg?.name);
            setTimeout(() => {
              setErrorResponse();
            }, 8000);
          }
        }).catch((err) => console.log(err));
      }
  };

  useEffect(() => {
    if (id) {
      Axios.get(`${axiosLink}leads/one?id=${id}`,{
        headers: {
          token: activeToken,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if(response.data){
          setFormData(response.data);
        } else {
          navigate("/");
        }
      })
      .catch((err) => console.log(err))
    }
  }, [id]);

  return (
    <div className='container py-5'>
      <div className="row justify-content-center">
        <div className="col-5">
          <h2>Add Leads</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="first_name" className="form-label">First Name:</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="form-label">Last Name:</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="affiliate_name" className="form-label">Affiliate Name:</label>
              <input
                type="text"
                className="form-control"
                id="affiliate_name"
                name="affiliate_name"
                value={formData.affiliate_name}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone:</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="country" className="form-label">Country:</label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="currency" className="form-label">Currency:</label>
              <input
                type="text"
                className="form-control"
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="created" className="form-label">Created:</label>
              <input
                type="text"
                className="form-control"
                id="created"
                name="created"
                value={formData.created}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="source_type" className="form-label">Source Type:</label>
              <input
                type="text"
                className="form-control"
                id="source_type"
                name="source_type"
                value={formData.source_type}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="source_comment" className="form-label">Source Comment:</label>
              <input
                type="text"
                className="form-control"
                id="source_comment"
                name="source_comment"
                value={formData.source_comment}
                onChange={handleChange}
              />
            </div>
            {id &&
              <>
                <div className="mb-3">
                  <label htmlFor="responsablie" className="form-label">Responsablie:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="responsablie"
                    name="responsablie"
                    value={formData.responsablie}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">Status:</label>
                  <select
                    className="form-select"
                    id="status"
                    name="status"
                    onChange={handleChange}
                    value={formData.status}
                  >
                    <option disabled value="" selected>Add status</option>
                    <option value={null}>No status</option>
                    {StatusTermin?.map((e) => (
                      <option key={e.name} value={e.id}>{e.name}</option>
                    ))}
                  </select> 
                </div>
              </>}
            <button type="submit" className="btn btn-primary" disabled={disabledBtn}>{id ? "Update" : "Add"}</button>
            {errorResponse && <p className="pt-4 he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorResponse}</p>}
            {successMsg && <p className="pt-4 color-green">{successMsg}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLeads;
