const StatusTermin = [
    {
        id: 1,
        name: "Termin"
    },
    {
        id: 2,
        name: "No Answer"
    },
    {
        id: 3,
        name: "High Potential"
    },
    {
        id: 4,
        name: "Bad Numbers"
    },
    {
        id: 5,
        name: "Wrong Numbers"
    },
    {
        id: 6,
        name: "Deposit"
    },
    {
        id: 7,
        name: "Not Interested"
    },
];

export {
    StatusTermin,
}