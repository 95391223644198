import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Token from '../../PrivateRoutes/Token';
import { axiosLink } from '../../axiosLink';
import { useNavigate, useParams } from 'react-router-dom';

const AddMenager = () => {
  const { activeToken } = Token();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    password: '',
    status: 'active'
  });

  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
      if (id) {
        Axios.put(`${axiosLink}users/updateUser`, formData, {
          headers: {
            token: activeToken,
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response?.data?.msg) {
            setSuccessMsg(response?.data?.msg);
            setTimeout(() => {
              navigate("/admin/menagers");
            }, 5000);
          } else {
            setDisabledBtn(false);
            setErrorResponse(response.data?.errMsg);
            setTimeout(() => {
              setErrorResponse();
            }, 8000);
          }
        }).catch((err) => console.log(err));
      } else {
        Axios.post(`${axiosLink}users/addmenager`, formData, {
          headers: {
            token: activeToken,
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response?.data?.msg) {
            setSuccessMsg(response?.data?.msg);
            setTimeout(() => {
              navigate("/admin/menagers");
            }, 5000);
          } else {
            setDisabledBtn(false);
            setErrorResponse(response.data?.errMsg);
            setTimeout(() => {
              setErrorResponse();
            }, 8000);
          }
        }).catch((err) => console.log(err));
      }
  };

  useEffect(() => {
    if (id) {
      Axios.get(`${axiosLink}users/one-client-full?id=${id}`,{
        headers: {
          token: activeToken,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if(response.data){
          setFormData(response.data);
        } else {
          navigate("/");
        }
      })
      .catch((err) => console.log(err))
    }
  }, [id]);

  return (
    <div className='container pt-5'>
      <div className="row justify-content-center">
        <div className="col-5">

          <h2>Add Menager</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="form-label">Last Name:</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password:</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required={!id}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="status" className="form-label">Status:</label>
              <select
                className="form-select"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <option value="active" selected>Active</option>
                <option value="disabled">Disabled</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary" disabled={disabledBtn}>{id ? "Update" : "Add"}</button>
            {errorResponse && <p className="pt-4 he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorResponse}</p>}
            {successMsg && <p className="pt-4 color-green">{successMsg}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMenager;
