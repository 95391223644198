import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";
import Modal from '../Modal';

const PublishedFree = () => {
  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  const [assign, setAssign] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const { activeToken } = Token();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(100); // Set your page size
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [toggleSelect, setToggleSelect] = useState(true);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const [successMsg, setSuccessMsg] = useState();
  
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData(page);
    setToggleSelect(true);
    setSelectedUserIds([]);
  };
  const getPaginationRange = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    
    return pages;
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
    getData(1, Number(event.target.value));
  };
  
  const handleSubmit = async () => {
    setDisabledBtn(true);
    Axios.put(`${axiosLink}leads/unpublish-leads`, { leads: selectedUserIds }, {
      headers: {
        token: activeToken,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response?.data?.msg) {
        setSuccessMsg(response?.data?.msg);
        getData(1);
        setTimeout(() => {
          setDisabledBtn(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setDisabledBtn(false);
        setErrorResponse(response.data?.errMsg);
        setTimeout(() => {
          setErrorResponse();
        }, 8000);
      }
    }).catch((err) => console.log(err));
  };

  const downloadExcel = async () => {
    try {
      const response = await Axios.post(`${axiosLink}leads/download-excel`, { data: user }, {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
      const currentDate = new Date();

      // Format the current date and time as needed
      const formattedDateTime = currentDate.toISOString().replace(/[-T:.]/g, '');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Leads ${formattedDateTime}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading Excel:', error);
    }
  };

  const setAssignAll = () => {
    if (toggleSelect) {
      const allIds = user?.map(element => element.id);
      setSelectedUserIds(allIds);
    } else {
      setSelectedUserIds([]);
    }
    setToggleSelect(!toggleSelect);
  };

  const publishLeads = (userId) => {
    setSelectedUserIds(prevIds => {
      if (prevIds.includes(userId)) {
        return prevIds.filter(id => id !== userId); // Deselect if already selected
      } else {
        return [...prevIds, userId]; // Select if not already selected
      }
    });
  };

  const assignFunc = () => {
    Axios.delete(`${axiosLink}leads/assign?useselectedUserrId=${selectedUser}&leadId=${assign?.id}`,
      {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      if (response.data?.msg) {
        getData();
        setSuccess(response.data?.msg);
        setTimeout(() => {
          setSelectedUser();
          setAssign();
          setSuccess();
        }, 4000);
      } else {
        setError(response.data?.err);
      }
    })
    .catch((err) => console.log(err));
  }

  const getData = (page, nrRows) => {
    const nrPageSize = nrRows || pageSize;
    Axios.get(`${axiosLink}leads/free-publish?offset=${(page - 1) * nrPageSize}&limit=${nrPageSize}`,
      {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
      .then((response) => {
        setUser(response?.data?.data);
        setTotalPages(Math.ceil(response?.data?.totalCount / nrPageSize)); // Assuming your API returns total count
      })
      .catch((err) => console.log(err));

    Axios.get(`${axiosLink}users/agents`,
      {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
      .then((response) => {
        setUserData(response?.data);
        // console.log("response", response);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData(1);
  }, []);
  return (
    <div className='container-fluid'>
      <div className="row my-4">
          <div className="col-6">
            <h3>Free Published Leads</h3>
          </div>
          <div className="col-6 d-flex justify-content-end">
          <button type="button" onClick={() => handleSubmit()} className="btn btn-primary mx-3" disabled={disabledBtn || user?.length === 0 || selectedUserIds.length === 0}>Unpublish from Manager</button>
            <button type="button" onClick={() => setAssignAll()} className={`${!toggleSelect ? "btn-danger" : "btn-primary"} btn  mx-1`} disabled={disabledBtn || user?.length === 0}>{toggleSelect ? "Select all": "Deselect all"}</button>
            <Link className="btn btn-primary text-uppercase" to="/leads/add">Add Leads</Link>
            {user?.length ? <button className="btn btn-success text-uppercase ml-10" onClick={downloadExcel}>Download Excel</button>:""}
          </div>
      </div>
      <div className="row my-2">
        <div className="col-12">
          <div className="container-fluid table-responsive">
            {errorResponse && <p className="pt-4 he-absolute he-d-block he-input__error-msg he-parag-small he-fw-medium he-mt-12 he-text-left">{errorResponse}</p>}
            {successMsg && <p className="pt-4 color-green">{successMsg}</p>}
            {user ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Affiliate Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Country</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Created AT</th>
                    <th scope="col">Source Type</th>
                    <th scope="col">Source Comment</th>
                    <th scope="col">Responsablie</th>
                    <th scope="col">Online Status</th>
                    <th>Publish leads to Manager</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.length === 0 && <tr><td colSpan={12}>No data!</td></tr>}
                  {user?.map((el, index) => (
                    <tr key={el.id} className={`status-${el.status}`}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.first_name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.affiliate_name}</td>
                      <td>{el.email}</td>
                      <td>{el.phone}</td>
                      <td>{el.country}</td>
                      <td>{el.currency}</td>
                      <td>{el.created}</td>
                      <td>{el.source_type}</td>
                      <td>{el.source_comment}</td>
                      <td><button onClick={() => setAssign(el)} className="btn btn-primary my-1">Assign</button></td>
                      <td>
                        <span>{el?.statuses[0]?.name}</span>
                      </td>
                      <td><button type='button' onClick={() => publishLeads(el.id)} className={`${selectedUserIds.includes(el.id) ? "btn-danger" : "btn-primary"} btn  my-1`}>{selectedUserIds.includes(el.id) ? "Remove" : "Unpublish"}</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
              <div className="col-12">
                <div className="pagination">
                  <button
                    className='page-link'
                    disabled={currentPage === 1} 
                    onClick={() => handlePageChange(currentPage - 1)}>
                    Previous
                  </button>
                  {getPaginationRange().map((page) => (
                    <button 
                      key={page} 
                      onClick={() => handlePageChange(page)} 
                      className={currentPage === page ? 'mx-2 page-link active' : 'page-link page-item mx-2'}>
                      {page}
                    </button>
                  ))}
                  <button 
                    className='page-link'
                    disabled={currentPage === totalPages} 
                    onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                  </button>
                  <div>
                    <select id="pageSize" value={pageSize} onChange={handlePageSizeChange} className="form-select w-auto mx-2">
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <br />
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={!!assign}
        handleClose={() => {
            setAssign();
            setSelectedUser();
          }
        }
        btn="Assign"
        triggerSave={assignFunc}
        title="Assign leads to:"
      >
        {success ? (<p className='text-success text-capitalize'>{success}</p>) : (
          <select
            className="form-select"
            id="status"
            name="status"
            onChange={(event) => handleChange(event)}
            value={selectedUser || ''}
            required
          >
            <option disabled selected value="">Select Agent</option>
            {userData?.map((el) => (
              <option key={el.id} value={el.id}>{el.name} {el.last_name} - {el.email}</option>
            ))}
          </select>   
        )}
        {error && <p className='color-red'>{error}</p>}
      </Modal>
    </div>
  )
}

export default PublishedFree;
