import React from 'react';
// import { useState, useEffect } from 'react';
// import Logo from "../../assets/img/brand-logo.svg";
import "./loader.scss";

const Loader = ({ showLoader }) => {
  // const [showloader, setShowLoader] = useState(false);
  // useEffect(() => {
  //       // setTimeout(() => {
  //       //   setShowLoader(showLoader)
  //       // }, 500);
  //       // eventListener();
  // }, [showLoader])
  return (
    <div className="showbox">
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
      </svg>
    </div>
  </div>
  )
}

export default Loader;
