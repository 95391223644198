import React from 'react';
import { Link } from 'react-router-dom';

const Admin = () => {
  return (
    <div>
      <div className="container d-flex align-items-center full-height">
        <div className="row justify-content-center w-100">
          <div className="col-lg-4">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">All Users</h5>
                <hr/>
                <div className="d-grid">
                  <Link to="/maintainer/agents" className="btn btn-primary text-uppercase">Agents</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">Leads</h5>
                <hr />
                <div className="d-grid">
                  <Link to="/maintainer/leads/free" className="btn btn-primary text-uppercase">Free Leads</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin