import React from 'react';
import { Link } from 'react-router-dom';

const Agent = () => {
  return (
    <div>
      <div className="container d-flex align-items-center full-height">
        <div className="row justify-content-center w-100">
          <div className="col-lg-4">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <div className="d-grid">
                  <Link to="/user/info" className="btn btn-primary text-uppercase">Termins</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <div className="d-grid">
                  <Link to="/user/leads" className="btn btn-primary text-uppercase">Leads</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agent
