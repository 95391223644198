import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";
import Modal from '../Modal';

const Menager = () => {
  const [user, setUser] = useState();
  const [deleteMenager, setDeleteMenager] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [editMode, setEditMode] = useState();
  const { activeToken } = Token();

  const handleChange = (e, ID) => {
    console.log("e", e.target.value);
    console.log("ID", ID);
    Axios.delete(`${axiosLink}users/toggleStatus?id=${ID}&status=${e.target.value}`,
    {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
    .then((response) => {
      console.log(response);
    })
    .catch(err => console.log(err));
  }

  const deleteFunc = () => {
    Axios.delete(`${axiosLink}users/delete?id=${deleteMenager?.id}`,
      {
          headers: {
              token: activeToken,
              'Content-Type': 'application/json',
          },
      })
      .then((response) => {
        if (response.data?.msg) {
          getData();
          setSuccess(response.data?.msg);
          setTimeout(() => {
            setDeleteMenager()
            setSuccess();
          }, 4000);
        } else {
          setError(response.data?.err);
        }
      })
      .catch((err) => console.log(err));
    }

    const getData = () => {
      Axios.get(`${axiosLink}users/menagers`,
        {
          headers: {
              token: activeToken,
              'Content-Type': 'application/json',
          },
      })
        .then((response) => {
          setUser(response?.data);
          console.log("response", response);
        })
        .catch((err) => console.log(err));
    }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='container'>
      <div className="row my-5">
          <div className="col-6">
            Menager
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button onClick={() => setEditMode(!editMode)} className="btn btn-warning text-uppercase mx-4" to="/admin/menagers/add">{editMode ? "Quit edit" : "Edit"}</button>
            <Link className="btn btn-primary text-uppercase" to="/admin/menagers/add">Add menager</Link>
          </div>
      </div>
      <div className="row my-5">
        <div className="col-12">
          <div className="container table-responsive py-5"> 
            {user ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Surname</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Status</th>
                    {editMode && <th scope="col"></th>}
                  </tr>
                </thead>
                <tbody>
                  {user?.map((el, index) => (
                    <tr key={el.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.email}</td>
                      <td>{editMode ?
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          onChange={(event) => handleChange(event, el.id)}
                          required
                        >
                          <option value="disabled" selected={el.status === "active"}>Active</option>
                          <option value="active" selected={el.status === "disabled"}>Disabled</option>
                        </select> 
                        : <span className={`${el.status === "active" ? "text-success" : "text-danger"}`}>{el.status}</span>}
                      </td>
                      {editMode && 
                        <td width="180px">
                          <Link to={`/admin/menagers/add/${el.id}`} className='btn btn-warning'>Edit</Link>
                          <button onClick={() => setDeleteMenager(el)} className="btn btn-danger mx-3">Delete</button>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
          </div>
        </div>
      </div>
      <Modal
        showModal={!!deleteMenager}
        handleClose={() => setDeleteMenager()}
        btn="Yes"
        triggerSave={deleteFunc}
        title="Delete"
      >
        {success ? (<p className='text-success text-capitalize'>{success}</p>) : (
          <p className='color-red'>Are u sure u want to delete: <br/>
        {deleteMenager?.name} {deleteMenager?.last_name}</p>)}
        {error && <p className='color-red'>{error}</p>}
      </Modal>
    </div>
  )
}

export default Menager;
