import React, { useState } from 'react';
import axios from 'axios';
import { axiosLink } from '../../axiosLink';

const ResetPassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    if (newPassword !== repeatPassword) {
      setMessage('Passwords do not match. Please enter them again.');
      return;
    }

    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      const response = await axios.put(
        `${axiosLink}account-security/reset-password`,
        { currentPassword, password: newPassword },
        {
          headers: {
            'Content-Type': 'application/json',
            'token': token
          }
        }
      );
      if (response.data.status) {
        setMessage(response.data.msg);
        // Clear input fields on successful password reset if needed
        setCurrentPassword('');
        setNewPassword('');
        setRepeatPassword('');
      } else {
        setMessage(response.data.msg);
      }
    } catch (error) {
      setMessage('Failed to reset password.');
    }
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPasswordChange = (event) => {
    setRepeatPassword(event.target.value);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Reset Password</h2>
              <form>
                <div className="form-group my-2">
                  <label htmlFor="currentPassword">Current Password:</label>
                  <input type="password" className="form-control" id="currentPassword" value={currentPassword} onChange={handleCurrentPasswordChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password:</label>
                  <input type="password" className="form-control" id="newPassword" value={newPassword} onChange={handleNewPasswordChange} />
                </div>
                <div className="form-group my-2">
                  <label htmlFor="repeatPassword">Repeat Password:</label>
                  <input type="password" className="form-control" id="repeatPassword" value={repeatPassword} onChange={handleRepeatPasswordChange} />
                </div>
                <button type="button" className="btn btn-primary" onClick={handleResetPassword}>Reset Password</button>
              </form>
              {message && <p className="mt-3 text-center">{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
