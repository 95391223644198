const menuItemAdmin = [
    {
        name: "Home",
        path: "/admin"
    },
    {
        name: "Menagers",
        path: "/admin/menagers"
    },
    {
        name: "Agents",
        path: "/admin/agents"
    },
    {
        name: "Leads",
        path: "/leads"
    }
];

const menuItemMaintainer = [
    {
        name: "Home",
        path: "/maintainer"
    },
    {
        name: "Agents",
        path: "/maintainer/agents"
    },
    {
        name: "Leads",
        path: "/maintainer/leads/free"
    }
];

const menuItemUser = [
    {
        name: "Home",
        path: "/user"
    },
    {
        name: "Leads",
        path: "/user/leads"
    }
];

export {
    menuItemAdmin,
    menuItemMaintainer,
    menuItemUser
}