// return the user data from the local storage
import { jwtDecode } from 'jwt-decode'; // Correct import statement

const Token = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    const { name, last_name, role, id } = decoded;
    const data = {
      user: `${name} ${last_name}`,
      activeToken: token,
      role: role,
      id: id
    };
    const tokenExp = decoded.exp;
    const time = new Date().getTime() / 1000;
    if (tokenExp < time) {
      localStorage.removeItem('token');
      return false;
    }
    return data;
  }
  localStorage.removeItem('token');
  return false;
};

export default Token;
