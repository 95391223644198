import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";

const Agents = () => {
  const [user, setUser] = useState();
  const [editMode, setEditMode] = useState();
  const { activeToken } = Token();

  const handleChange = (e, ID) => {
    console.log("e", e.target.value);
    console.log("ID", ID);
    Axios.delete(`${axiosLink}users/toggleStatus?id=${ID}&status=${e.target.value}`,
    {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
    .then((response) => {
      setEditMode(false);
      getData();
    })
    .catch(err => console.log(err));
  }

  const getData = () => {
    Axios.get(`${axiosLink}users/agents`,
    {
      headers: {
          token: activeToken,
          'Content-Type': 'application/json',
      },
  })
    .then((response) => {
      setUser(response?.data);
    })
    .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='container'>
      <div className="row my-5">
          <div className="col-6">
            <h3>Agents</h3>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button onClick={() => setEditMode(!editMode)} className="btn btn-warning text-uppercase mx-4" to="/admin/menagers/add">{editMode ? "Quit edit" : "Edit"}</button>
            <Link className="btn btn-primary text-uppercase" to="/admin/agents/add">Add Agent</Link>
          </div>
      </div>
      <div className="row my-5">
        <div className="col-12">
          <div className="container table-responsive py-5"> 
            {user ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Surname</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                    {editMode ? <th></th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {user?.map((el, index) => (
                    <tr key={el.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.email}</td>
                      <td>{editMode ?
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          onChange={(event) => handleChange(event, el.id)}
                          required
                        >
                          <option value="disabled" selected={el.status === "active"}>Active</option>
                          <option value="active" selected={el.status === "disabled"}>Disabled</option>
                        </select> 
                        : <span className={`${el.status === "active" ? "text-success" : "text-danger"}`}>{el.status}</span>}
                      </td>
                      {editMode && 
                        <td>
                          <Link to={`/admin/agents/update/${el.id}`} className='btn btn-warning'>Edit</Link>
                        </td>
                      }
                      <td>
                        <Link to={`/admin/agent/addleads/${el.id}`} className='btn btn-success'>Assign Leads</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agents;
