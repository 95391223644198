import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";
import Modal from '../Modal';
import { StatusTermin } from '../../assets/status';

const UserInfo = () => {
  const [userData, setUserData] = useState();
  const { activeToken, id } = Token();

    const getData = () => {
      Axios.get(`${axiosLink}leads/agent/termins?id=${id}`,
        {
          headers: {
              token: activeToken,
              'Content-Type': 'application/json',
          },
      })
        .then((response) => {
          console.log("---", response.data);
          setUserData(response?.data?.data);
        })
        .catch((err) => console.log(err));
    }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='container-fluid'>
      <div className="row my-4">
          <div className="col-6">
            <h3>Termins</h3>
          </div>
      </div>
      <div className="row my-2">
        <div className="col-12">
          <div className="container-fluid table-responsive"> 
            {userData ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Affiliate Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Country</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Created AT</th>
                    <th scope="col">Source Type</th>
                    <th scope="col">Source Comment</th>
                    <th scope="col">Online Status</th>
                    <th scope="col">Agent comment</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.length === 0 && <tr><td colSpan={13}>No data!</td></tr>}
                  {userData?.map((el, index) => (
                    <tr key={el.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.first_name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.affiliate_name}</td>
                      <td>{el.email}</td>
                      <td>{el.phone}</td>
                      <td>{el.country}</td>
                      <td>{el.currency}</td>
                      <td>{el.created}</td>
                      <td>{el.source_type}</td>
                      <td>{el.source_comment}</td>
                      <td>
                        {el?.statuses[0]?.name}
                      </td>
                      <td>{el.agent_comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo;
