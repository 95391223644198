import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { axiosLink } from '../../axiosLink';
import Token from '../../PrivateRoutes/Token';
import Loader from "../Loader/Loader";
import Modal from '../Modal';
import { StatusTermin } from '../../assets/status';

const Leads = () => {
  const [userData, setUserData] = useState();
  const [success, setSuccess] = useState();
  const [comment, setComment] = useState(null);
  const [leadSelected, setLeadSelected] = useState();
  const [error, setError] = useState();
  const { activeToken, id } = Token();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(100); // Set your page size

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData(page);
  };

  const getPaginationRange = () => {
    const pages = [];
    const maxVisiblePages = 5;
    
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    
    return pages;
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
    getData(1, Number(event.target.value));
  };

  const handleChange = (statusID, ID) => {
    Axios.delete(`${axiosLink}leads/status?id=${ID}&statusID=${statusID}&comment=${comment}`,
    {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
    .then((response) => {
        if (response.data?.msg) {
            getData();
            setSuccess(response.data?.msg);
            setTimeout(() => {
              setSuccess();
              setLeadSelected();
            }, 4000);
          } else {
            setError(response.data?.err);
          }
    })
    .catch(err => console.log(err));
  }

  const getData = (page, nrRows) => {
    const nrPageSize = nrRows || pageSize;
    Axios.get(`${axiosLink}leads/agent?id=${id}&offset=${(page - 1) * nrPageSize}&limit=${nrPageSize}`,
      {
        headers: {
            token: activeToken,
            'Content-Type': 'application/json',
        },
    })
      .then((response) => {
        setUserData(response?.data?.data);
        setTotalPages(Math.ceil(response?.data?.totalCount / nrPageSize)); // Assuming your API returns total count
      })
      .catch((err) => console.log(err));
  }  

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='container-fluid'>
      <div className="row my-4">
          <div className="col-6">
            <h3>Leads</h3>
          </div>
      </div>
      <div className="row my-2">
        <div className="col-12">
          <div className="container-fluid table-responsive"> 
            {userData ? (
              <table className="table table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Affiliate Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Country</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Created AT</th>
                    <th scope="col">Source Type</th>
                    <th scope="col">Source Comment</th>
                    <th scope="col">Online Status</th>
                    <th scope="col">Agent comment</th>
                    <th scope="col">Nr. of trying to call</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.length === 0 && <tr><td colSpan={14}>No data!</td></tr>}
                  {userData?.map((el, index) => (
                    <tr key={el.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{el.first_name}</td>
                      <td>{el.last_name}</td>
                      <td>{el.affiliate_name}</td>
                      <td>{el.email}</td>
                      <td>{el.phone}</td>
                      <td>{el.country}</td>
                      <td>{el.currency}</td>
                      <td>{el.created}</td>
                      <td>{el.source_type}</td>
                      <td>{el.source_comment}</td>
                      <td>
                        <select
                          className="form-select"
                          id="status"
                          name="status"
                          onChange={(event) => setLeadSelected({ ...el, statusId: event.target.value })}
                          value={leadSelected?.statusId || ""}
                        >
                          <option disabled value="" selected>Add status</option>
                          {StatusTermin?.map((e) => (
                            <option key={el.name} value={e.id}>{e.name}</option>
                          ))}
                        </select> 
                      </td>
                      <td>{el.agent_comment}</td>
                      <td>{el.try_call || 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>) : (
                <Loader />
              )}
          </div>
        </div>
        <div className="col-12">
          <div className="pagination">
            <button
              className='page-link'
              disabled={currentPage === 1} 
              onClick={() => handlePageChange(currentPage - 1)}>
              Previous
            </button>
            {getPaginationRange().map((page) => (
              <button 
                key={page} 
                onClick={() => handlePageChange(page)} 
                className={currentPage === page ? 'mx-2 page-link active' : 'page-link page-item mx-2'}>
                {page}
              </button>
            ))}
            <button 
              className='page-link'
              disabled={currentPage === totalPages} 
              onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </button>
            <div>
              <select id="pageSize" value={pageSize} onChange={handlePageSizeChange} className="form-select w-auto mx-2">
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <br />
        </div>
      </div>
      <Modal
        showModal={!!leadSelected}
        handleClose={() => setLeadSelected()}
        btn="Yes"
        triggerSave={() => handleChange(leadSelected?.statusId, leadSelected?.id)}
        title="Delete"
      >
        {success ? (<p className='text-success font-weight-bold text-capitalize'>{success}</p>) : (
          <p className='color-red'>
            Are u sure u want to change status for: <br/>
            <b>{leadSelected?.first_name} {leadSelected?.last_name} - {leadSelected?.email}</b>
            <br />
            <br />
            <div className="mb-3">
              <label htmlFor="comment" className="form-label">Comment:</label>
              <textarea
                type="text"
                rows="3"
                className="form-control"
                id="comment"
                name="comment"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </p>)}
        {error && <p className='color-red'>{error}</p>}
      </Modal>
    </div>
  )
}

export default Leads;
